import { useStaticQuery, graphql } from 'gatsby';

export const useSocialLinks = () => {
    const allSocialLinks = useStaticQuery(
        graphql`
            query GET_SOCIAL_LINK {
                allWordpressAcfOptions {
                    nodes {
                        options {
                            social_facebook_link
                            social_instagram_link
                            social_twitter_link
                            social_youtube_link
                            social_pinterest_link
                            social_yelp_link
                            cart_link
                        }
                    }
                }
            }
        `
    );
    return allSocialLinks.allWordpressAcfOptions.nodes[0].options;
};
